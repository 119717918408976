import React from "react";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/comptia-security/Hero";
  import Content from "../sections/comptia-security/Content";
  import CTA from "../sections/comptia-security/CTA";

const ComptiaSecurityPage = () => {
  return (
    <>
      <PageWrapper
        themeConfig={{
          footerStyle: 'style4'
        }}
      >
        <Hero />
        <Content />
        <CTA className="bg-images" />
      </PageWrapper>
    </>
  );
};
export default ComptiaSecurityPage;
