import React from 'react';

const Content = () => {
  return (
    <div className="pt-8 pt-md-10 pt-lg-15 pb-10 pb-md-10 pb-lg-13 mb-lg-1">
      <div className="container">
        <div
          className="row align-items-center justify-content-center"
          data-aos="fade-right"
          data-aos-delay={300}
        >
          <div className="col">
            <h2 className="font-size-8 mb-5 pr-xs-23 pr-sm-8 pr-md-18 pr-lg-18">
              Course Description
            </h2>
            <p className="justify-text font-size-6 pr-xl-13">
              CompTIA Security+ is a global certification that validates the baseline skills necessary to perform core security functions and pursue an IT security career.<br />
              The CompTIA Security+ course is designed to help prepare students for the SY0-601 exam. Students will implement and monitor security on networks, applications and operating systems and respond to security breaches.
            </p>
            <h2 className="font-size-8 mb-5 pr-xs-23 pr-sm-8 pr-md-18 pr-lg-18">
              Target Students
            </h2>
            <p className="justify-text font-size-6 pr-xl-13">
              This course is targeted toward an Information Technology (IT)
              professional who has networking and administrative skills in
              Windows-based TCP/IP networks and familiarity with other operating
              systems; such as OS X, Unix (or Linux) and who wants to further a
              career in IT by acquiring a foundational knowledge of security
              topics; prepare for the CompTIA Security+ Certification
              examination or use Security+ as the foundation for advanced
              security certifications or career roles.
            </p>
            <h2 className="font-size-8 mb-5 pr-xs-23 pr-sm-8 pr-md-18 pr-lg-18">
              Course Objectives
            </h2>
            <p className="justify-text font-size-6 pr-xl-13">
              Upon successful completion of this course, students will be able
              to: <br />
              - Identify the fundamental concepts of computer security. <br />
              - Identify security threats and vulnerabilities. <br />
              - Examine network security. <br />
              - Manage application, data and host security. <br />
              - Identify access control and account management security
              measures. <br />
              - Manage certificates. <br />
              - Identify compliance and operational security measures. <br />
              - Manage risk. <br />
              - Manage security incidents. <br />- Develop business continuity
              and disaster recovery plans.
            </p>
            <h2 className="font-size-8 mb-5 pr-xs-23 pr-sm-8 pr-md-18 pr-lg-18">
              Course Content
            </h2>
            <p className="justify-text font-size-6 pr-xl-13">
              - Introduction to computing (free tutorial) <br />
              - Security fundamentals <br />
              - Identifying security threats and vulnerabilities <br />
              - Managing data, application and host security <br />
              - Implementing network security <br />
              - Implementing access control, authentication and access
              management <br />
              - Managing certificates <br />
              - Implementing compliance and operational security <br />
              - Risk management <br />
              - Troubleshooting and managing security incidence
              <br />- Business continuity and disaster recovery planning
            </p>
            <h2 className="font-size-8 mb-5 pr-xs-23 pr-sm-8 pr-md-18 pr-lg-18">
              Learning Outcomes
            </h2>
            <p className="justify-text font-size-6 pr-xl-13">
              Successful completion of the course and exams presents many
              opportunities in the field of work; notable among which are:{' '}
              <br />
              - Security Analyst <br />
              - Security Engineer or Architect <br />
              - Security/IT Director or Manager <br />
              - CISO/CSO, Systems Administrator <br />
              - Network Architect or Engineer <br />
              - Forensic Investigator - Auditor <br />- Systems Engineer or
              Integrator
            </p>
            <h2 className="font-size-8 mb-5 pr-xs-23 pr-sm-8 pr-md-18 pr-lg-18">
              Duration
            </h2>
            <p className="justify-text font-size-6 pr-xl-13">
              The course is delivered twice in every year; March Session and September Session. Each session is scheduled to be completed within 3 months; with
              <br />
              - 2 hours per session <br />
              - 2 sessions per week <br />
              - 12 sessions per quarter <br />
              - 12 sessions per delivery month (March or September) <br />
              - Small class size of up to 10 students per group (to facilitate interactive lessons)

            </p>
            <h2 className="font-size-8 mb-5 pr-xs-23 pr-sm-8 pr-md-18 pr-lg-18">
              Student Certification
            </h2>
            <p className="justify-text font-size-6 pr-xl-13">
              Certificate of participation will be issued to every student as
              evidence for successful completion of the course of study.
            </p>
            <h2 className="font-size-8 mb-5 pr-xs-23 pr-sm-8 pr-md-18 pr-lg-18">
              Approach to Learning
            </h2>
            <p className="justify-text font-size-6 pr-xl-13">
              -	Small Class Sizes<br/>
              -	Interactive Sessions<br/>
              -	Practical, hands-on<br/>
              -	Real Industry Experience<br/>
              -	Learning materials; Handbooks, Wall Charts, Insightful Videos 

            </p>
            <h2 className="font-size-8 mb-5 pr-xs-23 pr-sm-8 pr-md-18 pr-lg-18">
              Payment plan
            </h2>
            <p className="justify-text font-size-6 pr-xl-13">
              Participants can take advantage of our numerous installment options available. However, participants will be required to pay registration fees before enrollment into course.
            </p>
            <h2 className="font-size-8 mb-5 pr-xs-23 pr-sm-8 pr-md-18 pr-lg-18">
              Discount promotion
            </h2>
            <p className="justify-text font-size-6 pr-xl-13">
              Earn discounts on the total payable fees upon referral of other students to the training centre. Group discounts are also available for organizations that enroll their teams or entire departments for training.
            </p>
            <h2 className="font-size-8 mb-5 pr-xs-23 pr-sm-8 pr-md-18 pr-lg-18">
              Industry Experience
            </h2>
            <p className="font-size-6 pr-xl-13">
              A month’s internship after training
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
